import mobilityBanner from './assets/mobilitybanner.webp';
import './App.css';
import 'react-tabs/style/react-tabs.css';
import mds from './mobiledevelopmentservice.json';
import MobileAppDevelopment from './MobileAppDevelopment.json';
import lifecycle from './lifecycle.json';
import mobilityHowWeWork from './mobilityHowWeWork.json';
import productDevelopment from './productDevelopment.json';
import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import {  scrollToTop } from "./App";
import Project from "./project.js"
import { Link } from 'react-router-dom';
import Mobilefaq from './mobile-app-faq'
function MobileappDevelopment() {
    useEffect(() => {
    
        scrollToTop();
     
      }, [])
  return (
    <div className="App Mobility">
     <MetaTags>
            
            <meta name="description" content="Get reliable mobile app development services at Arccus. From native to cross-platform solutions, we help businesses in India & the USA succeed. Contact us!"/>
            <meta name="keyword" content="mobile app developer, mobile app development, mobile application development" />
            <meta property="og:title" content="Mobile App Development Company in India, USA" />
            <link rel="canonical" href="https://arccusinc.com/mobile-app-development" />
            <title>Leading Mobile App Development Company | Arccus Inc</title>
      </MetaTags>
     
      <section className=' banner-sec mobility'>
        <div className='container'>
            <div className='row align-items-center'>
                {/* <div className='col-md-6'>
                    <div className='banner-img text-center'>
                    <img src={mobilityBanner} alt={"mobility banner"}  />
                    </div>
                </div> */}
                <div className='col-12'>
                    <div className='banner-content text-center'>
                        <h1>Top Mobile App Development <br/> <span className='txt-with-bg'>Services</span></h1>
                        <p>Our custom <b>mobile app development</b> services will help you engage your users in interactive mobile applications. You have our back for every step of mobile app development. </p>
                        
                        <Link to="/contact-us" className='quote'>Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
      </section>

      <section className='MobileDevelopmentService'>
        <div className='container'>
            <h2 className='line-bottom'>Mobile Development  <span className='lastword'>Services</span></h2>
            <div className='row'>
                {mds.map( mds => {
                      return(
                <div className='col-md-6 text-center'>
                    <div className='mds-box'>
                        <div className='top-content'>
                            <h3>{mds.title}</h3>
                            <div className='d-flex serviceIconRow'>
                                { mds.images.map( ps => { 
                                return(
                                <img src={ps} alt={"service"} />
                                )})}
                            </div>
                        </div>
                        <p>{mds.content}</p>
                    </div>    
                </div>
                )})}
            </div>
        </div>
      </section>

      <section className='Product-Development_'>
          <div className='container'>
              <h2 className='line-bottom'>Comprehensive Approach To Product <span className='lastword'>Development</span>    </h2> 
              <div className=' row'>
                    {productDevelopment.map( productDevelopment => {
                        return(
                   <div className='col-lg-3 col-sm-6 Produce-Development-Step position-relative left-shadow'>
                        <div className='product-dev'>
                            <img src={productDevelopment.icon} alt={"product development"} />
                            <h3>{productDevelopment.title}</h3>
                            <p>{productDevelopment.content}</p>
                            <img src={productDevelopment.image} className="aerrow-img" alt={"aerrow"} />
                        </div>
                   </div> 
                   )})} 
              </div>
          </div>
      </section>

      <section className='why-choose-for-mad'>
          <div className='container'>
              <h2 className='line-bottom'>Why Choose <span className='lastword'>Arccus</span> For Mobile App Development   </h2> 
              <div className='row'>
              {MobileAppDevelopment.map( MobileAppDevelopment => {
                return(
                 <div className='col-md-4 text-center'>
                    <div className='mad-card'>
                        <img src={MobileAppDevelopment.icon} alt={"icon"} loading='lazy' />
                        <h3>{MobileAppDevelopment.title}</h3>
                        <p>{MobileAppDevelopment.content}</p>
                    </div>
                 </div>
                 )})} 
              </div>
          </div>
      </section>

      <section className='how-we-work'>
        <div className='container'>
            <h2 className='line-bottom'>How We  <span className='lastword'>Work </span></h2> 
            <div className='row'>
               {mobilityHowWeWork.map( mobilityHowWeWork => {
                return(
                <div className='col-md-6'>
                    <div className='mobility-HowWeWork'>
                        <img src={mobilityHowWeWork.icon} alt={"mobility"} loading='lazy'  />
                        <h3>{mobilityHowWeWork.title}</h3>
                        <p>{mobilityHowWeWork.content}</p>
                    </div>
                </div>
                )})} 
            </div>
        </div>
      </section>

      <section className='How-It-Work'>
          <div className='container'>
               <h2 className='line-bottom'>Full Mobile App Development  <span className='lastword'>Lifecycle</span></h2> 
               {lifecycle.map( lifecycle => {
                return(
               <div className='row how-it-work-row align-items-center'>
                    <div className='col-md-6 text-center'>
                       <img src={ lifecycle.image } alt={"lifecycle"} loading='lazy' />
                    </div>
                    <div className='col-md-6'>
                        <h3>{ lifecycle.MainHead }</h3>
                        <h4>{ lifecycle.SubHead }</h4>
                        <p>{ lifecycle.text }</p>
                    </div>
               </div>
               )})}     
          </div>
      </section>

      <section className='toward-greatness text-center'>
           <div className='container'>
                <h2 className='line-bottom'>Refine your Vision and push it toward   <span className='lastword'>greatness</span></h2> 
                <Link to="/contact-us" className='quote'>Contact Us</Link>
           </div>
      </section>

      <section className='project'>
           <div className='container position-relative'>
              <div className='carosoul-control'>
                        <h2>View Our Featured  Client  <span className='lastword'>Projects</span></h2>
                </div>
                 
                <div className='position-class'>
                  <div className='project-carosoul '>
                    <Project />
                  </div>  
                </div> 
                {/* <p className='text-center mt-5'>
                  <a href='https://works.arccusinc.com' target='blank' className='quote ml-auto'>Show More</a>
                </p> */}
            </div>
      </section>

      
    </div>
  );
}

export default MobileappDevelopment;
